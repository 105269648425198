import axios from 'axios';
import { client } from '../clients';


// Get the current URL of the page
const currentUrl = window.location.href;

// Parse the URL to extract the subdomain
const subdomain = currentUrl.match(/^(?:https?:\/\/)?([^\/]+)/)[1].split('.')[0];

// Create the base URL with the subdomain
const baseURL = `http://${subdomain}.localhost:8000/`;

// Create the Axios instance with the custom baseURL
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "x-app": "web",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Add request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor
api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Customize error handling as per your requirements
    console.error('API Error:', error);
    
    if (
      error.response &&
      [401, 403, 422].includes(error.response.status) &&
      !window.location.href.endsWith(".tech/") &&
      !window.location.href.endsWith(".tech") &&
      !window.location.href.endsWith("/login")
    ) {
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      window.location = "/login?error=timeout"
    }

    throw error;
  }
);

const API_URL = client.apiUrl;

export const changePassword = async (password, change_password_token ) => {
  const requestBody = {
    password,
    change_password_token
  };

  try {
    const response = await api.post(`${API_URL}/account/change-password`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createAccount = async (name, email ) => {
  const requestBody = {
    name,
    email
  };

  try {
    const response = await api.post(`${API_URL}/account`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAccount = async () => {
  try {
    const response = await api.get(`${API_URL}/account`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const login = async (username, password) => {
  const requestBody = {
    username,
    password,
  };

  try {
    const response = await api.post(`${API_URL}/auth`, requestBody);
    const token = response.access_token; 
    localStorage.setItem("access_token", token);
    localStorage.setItem("user_email", response.email);
    localStorage.setItem("user_name", response.name);
    localStorage.setItem("allow_services", JSON.stringify(response.allow_services ?? null));
    return response;
  } catch (error) {
    throw error;
  }
};


export const createPhoneSurvey = async (phone) => {
  const requestBody = {
    phone,
  };

  try {
    const response = await api.post(`${API_URL}/phone-survey`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
};

export const listPhoneSurveys = async () => {
  try {
    const response = await api.get(`${API_URL}/phone-survey`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Redirect to the login page
      localStorage.removeItem("access_token");
      return error.response.status; 
    } else {
      throw error;
    }
  }
};


export const getPhoneSurvey = async (id) => {
    try {
      const response = await api.get(`${API_URL}/phone-survey/${id}`);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to the login page
        localStorage.removeItem("access_token");
        return error.response.status; 
      } else {
        throw error;
      }
    }
  };
  
  export const createTargetSurvey = async ({cpfCnpj, type}) => {
    const requestBody = {
      cpf_cnpj: cpfCnpj,
      type,
    };
  
    try {
      const response = await api.post(`${API_URL}/target-survey`, requestBody);
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  export const listTargetSurveys = async () => {
    try {
      const response = await api.get(`${API_URL}/target-survey`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  export const getTargetSurvey = async (id) => {
    try {
      const response = await api.get(`${API_URL}/target-survey/${id}`);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("access_token");
        return error.response.status; 
      } else {
        throw error;
      }
    }
  };
  
  export default api;