import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { login } from "../services/api";
import logoInquest from "../assets/img/inquest_logo.jpg";
import "../sass/pages.scss";
import { message, Row, Col } from "antd";
import { client } from "../clients";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get("error");
    if (error === "timeout") {
      setError(
        "Desculpe, o tempo de login foi excedido. Por favor, faça login novamente."
      );
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    try {
      message.loading("Realizando login...", 1.5);
      const response = await login(email, password);
      localStorage.setItem("is_admin", response.is_admin);
      // Handle successful login here, e.g., redirect the user or perform other actions
      navigate("/search");
    } catch (error) {
      if (error.response) {
        // Request was made and the server responded with a status code outside the range of 2xx
        if (error.response.status === 401) {
          setError("Invalid email or password. Please try again.");
        } else {
          setError("An error occurred during login. Please try again later.");
        }
      } else if (error.request) {
        // The request was made but no response was received
        setError("No response from the server. Please try again later.");
      } else {
        // Something else happened while setting up the request
        setError("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <Container className="login-page">
      <Row justify="end">
        <Col>
          <div className="logo-bradesco">
            <img src={client.logoImg} alt="" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={10}>
          <div className="form-wrapper">
            <div className="inquest-logo">
              <img src={logoInquest} alt="" />
            </div>
            <h6>Bem-vindo de volta</h6>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail" className="my-4">
                <Form.Control
                  className="w-100 inquest-input"
                  type="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isInvalid={validated && !email}
                />
                <Form.Control.Feedback type="invalid">
                  Insira um email válido.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPassword" className="my-4">
                <Form.Control
                  className="w-100 inquest-input my-3"
                  type="password"
                  placeholder="Senha"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={validated && !password}
                />
                <Form.Control.Feedback type="invalid">
                  Insira uma senha.
                </Form.Control.Feedback>
              </Form.Group>

              {error && <div className="text-danger mb-3">{error}</div>}

              <div className="my-3 remember-me-div">
                {/*  <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  className="natural-width"
                  type="checkbox"
                  label="Lembrar de mim"
                />
              </Form.Group> */}

                {/* <div>
                <Link className="inquest-link"> Esqueci minha senha </Link>
              </div> */}
              </div>

              <div className="login-form-actions">
                <Button
                  className="inquest-button w-100"
                  variant="primary"
                  type="submit"
                >
                  Entrar
                </Button>
              </div>
            </Form>

            {/* <div className="mt-3">
            <span> Não tem conta? </span> <Link className="inquest-link"> Criar cadastro </Link>
          </div> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
