import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';


const Orders = ({ data }) => {
  const navigate = useNavigate();

  const getDataHeader = () => {
    if (data[0]?.type === "cpfcnpj") {
      return 'CPF/CNPJ pesquisado';
    } else if (data[0]?.type === "phone") {
      return 'Telefone pesquisado';
    }
    return '';
  };

  const handleViewSurvey = async (row) => {  
    if(row.status !== "completed") return
    navigate(`/report/${row.id}`);
  };

  

  const handleTargetSurvey = async (row) => {
    if (!row.link) return
    window.open(row.link, "_blank")
  };

  const getStatus = (status) => {
    switch(status) {
      case "idle":
        return "Pendente"
      case "loading":
        return "Carregando"
      case "completed":
        return "Completa"
      case "error":
          return "Resultado inesperado"
      default:
        return status
    }
  }

  return (
    <div className="component">
      <div className="row header">
        <div className="column">{getDataHeader()}</div>
        <div className="column">Nome</div>
        <div className="column">Criado em</div>
        <div className="column">Status da busca</div>
        <div className="column">Visualizar</div>
      </div>
      {data?.map((row, index) => (
        <div className="row" key={index}>
          <div className="column">{row.phone ?? row.cpf_cnpj}</div>
          <div className="column">{row.name ?? " - "}</div>
          <div className="column">{row.created_at}</div>
          <div className={`column status-${row.status && row.status.toLowerCase()}`}>
            <Tooltip title={row.status === "error" ? (row.error_message ?? "") : row.status}>
              {row.status === "error" 
                ?  (row.error_message ?? "Não encontrado na base de dados") 
                : getStatus(row.status)}
            </Tooltip>
          </div>
          <div className="column">
            <EyeOutlined
              className='icon-show-search'
              data-disabled={(row.phone && row.status !== "completed") || (row.cpf_cnpj && !row.link)}
              style={{ marginRight: '5px',  }}
              onClick={() => row.phone ? handleViewSurvey(row) : handleTargetSurvey(row)}
            />
          </div>
        </div>
      ))}
      {data?.length === 0 && (
        <div className='row' style={{textAlign: "center"}}>
          <div className="column">Nenhuma busca realizada</div>
        </div>
      )}
    </div>
  );
};

export default Orders;
