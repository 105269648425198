import { Col, Radio, Layout, message, Row } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import BasicHeader from "../component/header";
import { createPhoneSurvey, createTargetSurvey } from "../services/api";
import logoPrev from "../assets/img/PREV.png";
import logoPersonalizado from "../assets/img/PERSONALIZADO.png";
import { cnpj, cpf } from "cpf-cnpj-validator";
import "../sass/pages.scss";
import { client } from "../clients";

const { Content } = Layout;

function validateBearerCode(bearerCode) {
  // Check if the bearer code is empty or invalid
  if (!bearerCode || bearerCode.length < 6) {
    return false;
  }
  // Additional validation logic if needed

  return true;
}

function isValidInput(value, minLength) {
  return value && value.length >= minLength;
}

function PhoneForm(props) {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const getMask = () => {
    const phoneNumbers = phone.replace(/[^0-9]/g, "");
    return phoneNumbers.length > 5 && phoneNumbers[2] === "9"
      ? "(99) 99999-9999"
      : "(99) 9999-9999";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    setError("");

    let sanitizedPhone = "";

    try {
      sanitizedPhone = phone.replace(/\s|\(|\)|-/g, "");

      if (sanitizedPhone.length < 10) throw new Error();
    } catch (err) {
      setError("Telefone deve ter 10 ou 11 dígitos com o DDD");
      return;
    }

    try {
      message.loading("Criando busca");
      const response = await createPhoneSurvey(sanitizedPhone);

      if (response === 401) {
        navigate("/login?error=timeout"); // Redirect to the login page
        return;
      }

      navigate("/orders/phones");
    } catch (err) {
      setError("Erro ao criar busca por telefone");
    }
  };

  const isPhoneValid = isValidInput(phone.replace(/[^0-9]/g, ""), 10);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="telefoneInput">
        <Form.Label>Telefone</Form.Label>
        {error && <div className="text-danger mb-3">{error}</div>}
        <InputMask
          className="form-control inquest-input"
          mask={getMask()}
          value={phone}
          onChange={handlePhoneChange}
          placeholder="DDD+Telefone"
        ></InputMask>
      </Form.Group>
      <Button
        className="inquest-button mt-3"
        variant="primary"
        type="submit"
        disabled={!isPhoneValid}
      >
        Buscar
      </Button>
    </Form>
  );
}

function hasService(allowServices, service) {
  return (
    !allowServices ||
    allowServices?.length === 0 ||
    allowServices?.includes(service)
  );
}

function DeepSearchForm(props) {
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [mask, setMask] = useState("999.999.999-99");
  const [error, setError] = useState("");
  const [caseType, setCaseType] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setCaseType(client.searchTypes[0]);
  }, []);

  const validadeCpfCnpj = (value) => {
    const val = (value || "").replace(/[^0-9]/g, "");

    if (val && val.length > 1) {
      if (val.length > 11) {
        if (cnpj.isValid(val)) return val;

        throw new Error("CNPJ inválido");
      }

      if (!cpf.isValid(val)) throw new Error("CPF inválido");
    } else {
      throw new Error("CPF ou CNPJ inválido");
    }

    return val;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const form = e.currentTarget;

    const input = document.getElementById("input_cpf_cnpj");

    setError("");

    let sanitizedCpfCnpj = "";

    try {
      message.loading("Criando busca");
      sanitizedCpfCnpj = await validadeCpfCnpj(input.value);
    } catch (err) {
      setError(err.message);
      return;
    }

    try {
      const response = await createTargetSurvey({
        cpfCnpj: sanitizedCpfCnpj,
        type: caseType,
      });

      if (response === 401) {
        navigate("/login?error=timeout"); // Redirect to the login page
        return;
      }

      navigate("/orders/targets");
    } catch (err) {
      setError("Não foi possível realizar busca por CPF/CNPJ");
    }
  };

  const handleCpfCnpjChange = (event) => {
    try {
      setCpfCnpj(event.target.value);
      validadeCpfCnpj(event.target.value);
      setError("");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleKeyDown = (event) => {
    const key = event.key;
    const currentValue = event.target.value.replace(/[^0-9]/g, "");
    let predictedValueSize;

    if (key.length === 1) {
      var predictedValue = currentValue + key;
      predictedValueSize = predictedValue.length;
    } else {
      predictedValue = currentValue;
      predictedValueSize = predictedValue.length - 1;
    }

    if (predictedValueSize <= 11) {
      setMask("999.999.999-99");
    } else {
      setMask("99.999.999/9999-99");
    }
  };

  const isCpfCnpjValid =
    isValidInput(cpfCnpj.replace(/[^0-9]/g, ""), 11) ||
    isValidInput(cpfCnpj.replace(/[^0-9]/g, ""), 14);

  const onChangeRadio = (e) => {
    setCaseType(e.target.value);
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {client.searchTypes.length > 1 && (
        <Row align="start" className="radio-buttons-case-type">
          <Radio.Group onChange={onChangeRadio} value={caseType}>
            {client.searchTypes.includes("simples") &&
            hasService(props.allowServices, "simples") ? (
              <Col span={24}>
                <Radio value={"simples"}>Busca simples</Radio>
              </Col>
            ) : null}
            {client.searchTypes.includes("preventivo") &&
            hasService(props.allowServices, "preventivo") ? (
              <Col span={24}>
                <Radio value={"preventivo"}>Busca preventiva</Radio>
              </Col>
            ) : null}
            {client.searchTypes.includes("custom") &&
            hasService(props.allowServices, "custom") ? (
              <Col span={24}>
                <Radio value={"custom"}>Buscar dados básicos</Radio>
              </Col>
            ) : null}
            {client.searchTypes.includes("custom-veiculos") &&
            hasService(props.allowServices, "custom-veiculos") ? (
              <Col span={24}>
                <Radio value={"custom-veiculos"}>
                  Buscar veículos e dados básicos
                </Radio>
              </Col>
            ) : null}
          </Radio.Group>
        </Row>
      )}
      <Form.Group controlId="formCpfCnpj">
        {client.searchTypes.length === 1 ? (
          <Form.Label>{"CPF/CNPJ"}</Form.Label>
        ) : (
          <br />
        )}
        {error && <div className="text-danger mb-3">{error}</div>}
        <InputMask
          id="input_cpf_cnpj"
          className="form-control inquest-input"
          type="text"
          mask={mask}
          placeholder="Digite um CPF ou CNPJ"
          value={cpfCnpj}
          onChange={handleCpfCnpjChange}
          onKeyDown={handleKeyDown}
          required
        ></InputMask>
      </Form.Group>
      <Button
        className="inquest-button mt-3"
        variant="primary"
        type="submit"
        disabled={!isCpfCnpjValid}
      >
        Buscar
      </Button>
    </Form>
  );
}

export function Search(props) {
  const navigate = useNavigate();
  const [allowServices, setAllowServices] = useState([]);

  useEffect(() => {
    // Check the bearer code when the component mounts
    const token = localStorage.getItem("access_token");
    const isValidBearerCode = validateBearerCode(token);
    if (!isValidBearerCode) {
      // Redirect to the login page
      navigate("/login?error=timeout");
    } else {
      const allow = localStorage.getItem("allow_services");
      setAllowServices(JSON.parse(allow ?? "[]"));
    }
  }, [navigate]);

  return (
    <>
      <BasicHeader showUser={true} />
      <Content>
        <Row style={{ width: "100%" }} justify="center">
          <Col lg={20} md={20} sm={24} xs={24}>
            <h3>Buscar</h3>
            <div>
              {" "}
              Busque por pessoas <strong>físicas</strong> e{" "}
              <strong>jurídicas</strong>
            </div>
            <Row
              className="search_form-wrapper"
              justify="center"
              align={"middle"}
              gutter={[16, 16]}
            >
              {client.enabledSearch.includes("phone") && hasService(allowServices, "phone") ? (
                <Col className="card-form" lg={13} md={13} sm={24} xs={24}>
                  <h5>Busca por telefone</h5>
                  <div className="subtitle">
                    Realize uma busca rápida de dados com o número de telefone
                    da pessoa que deseja pesquisar.
                  </div>
                  {/* <div className="content-img-search">
                    <img
                      className="search-logo invisible"
                      src={logoPrev}
                      alt=""
                    />
                  </div> */}
                  <div className="content-search">
                    <PhoneForm />
                  </div>
                </Col>
              ) : null}
              {client.enabledSearch.includes("cpf_cnpj") && (
                <Col className="card-form" lg={13} md={13} sm={24} xs={24}>
                  <h5>Busca por CPF/CNPJ</h5>
                  <div className="subtitle">
                    Realize um busca mais completa.
                  </div>
                  {/* <div className="content-img-search">
                    {client.searchTypes.includes("preventivo") ? (
                      <img className="search-logo" src={logoPrev} alt="" />
                    ) : client.searchTypes.includes("custom") ? (
                      <img
                        className="search-logo"
                        src={logoPersonalizado}
                        alt=""
                      />
                    ) : null}
                  </div> */}
                  <div className="content-search">
                    <DeepSearchForm allowServices={allowServices} />
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Content>
    </>
  );
}
