import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Orders from '../component/orders';
import { Spin } from 'antd';
import { listPhoneSurveys, listTargetSurveys } from '../services/api';
import BasicHeader from '../component/header';
import "../sass/pages.scss";

function validateBearerCode(bearerCode) {
  // Check if the bearer code is empty or invalid
  if (!bearerCode || bearerCode.length < 6) {
    return false;
  }
  // Additional validation logic if needed

  return true;
}

export function Order() {
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(true)
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    // Check the bearer code when the component mounts
    const token = localStorage.getItem("access_token");
    const isValidBearerCode = validateBearerCode(token);
    if (!isValidBearerCode) {
      // Redirect to the login page
      navigate('/login?error=timeout');
    }
  }, [navigate]);


  useEffect(() => {
    if(window.location.href.includes("/phones")) {
      fecthPhones()
    } else if(window.location.href.includes("/targets")){
      fecthTargets()      
    }
  }, [window.location.href])

  const fecthPhones = async () => {
    setLoading(true)
    const response = await listPhoneSurveys();
  
    if (response === 401) {
       navigate('/login?error=timeout'); 
      return;
    }

    const data = response.items.map((survey) => {
      return { ...survey, type: 'phone'};
    });
    
    setData(data)
    setLoading(false)
  }

  const fecthTargets = async () => {
    setLoading(true)
    const response = await listTargetSurveys();
  
    if (response === 401) {
       navigate('/login?error=timeout'); 
      return;
    }

    const data = response.items.map((survey) => {
      return { ...survey, type: 'cpfcnpj'};
    });
    
    setData(data)
    setLoading(false)
  }

  // Determine the heading and description based on data.type
  let heading = '';
  let description = '';

  if (location.pathname.includes('phone')) {
      heading = 'Buscas por telefone';
      description = 'Histórico de buscas por telefone de pessoas físicas e jurídicas.';
  } else if (location.pathname.includes('targets')) {
    heading = 'Buscas por CPF/CNPJ';
    description = 'Histórico de buscas por pessoas físicas e jurídicas.';
  }


  return (
    <>
    <BasicHeader />
    <div className='structure-orders-page'>
      {isLoading ? (
        <div style={{width: "100%", textAlign: "center"}}>
          <Spin size="large" /> 
        </div>
      ) : (
        <>
          <h1>{heading}</h1>
          <p>{description}</p>
          <br/>
          <Orders data={data} />
        </>
      )}
    </div>
    </>
  );
}

export default Order;
