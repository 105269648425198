import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { createAccount } from '../services/api';
import BasicHeader from '../component/header';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import "../sass/pages.scss";
import { message } from 'antd';

function isValidInput(value, minLength) {
  return value && value.length >= minLength;
}

export function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError('');

    if (!isValidInput(name, 1) || !isValidInput(email, 1)) {
      setError('Todos os campos são obrigatórios');
      return;
    }

    try {
      message.loading("Cadastrando usuário...", 2)
      const response = await createAccount(name, email);

      if (response === 401) {
        navigate('/login?error=timeout'); // Redirect to the login page
        return;
      }

      // Handle successful registration here, e.g., show success message or redirect user
      console.log(response);
      Swal.fire('Sucesso', 'Usuário cadastrado!', 'success');
    } catch (error) {
      setError(error?.response?.data?.errors?.message || "Não foi possível realizar o cadastro.");
      // Handle registration error here, e.g., display an error message
      console.error('Falha no Registro:', error);
    }
  };

  return (
    <>
      <BasicHeader />
      <Container className="register-form">
        <h1>Cadastro</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="nameInput">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entre com o Nome do usuário"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="emailInput">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Entre com o email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          {error && <p className="error-message">{error}</p>}

          <Button variant="primary" type="submit">
            Salvar
          </Button>
        </Form>
      </Container>
    </>
  );
}

export default Register;
