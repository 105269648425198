import React, { useEffect, useMemo, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Menu, Row, Col, Layout } from 'antd';
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoInquest from "../assets/img/inquest_logo.jpg"
import { LogoutOutlined } from "@ant-design/icons";
import { client } from "../clients";

const { Header } = Layout;

const BasicHeader = ({ hasMenu, menuKey, showUser }) => {
    const [current, setCurrent] = useState(null);
    const [items, setItems] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const buildMenuItems = () => {

      if(menuKey === "unlogged") {
        return [
          {
            label: 'Ir para login',
            icon: null,
            key: 'search',
            path: '/login',
            onClick: () => {
              navigate("/login")
            }
          },
        ]    
      }
      
      const list = [
        {
          label: 'Nova pesquisa',
          icon: null,
          key: 'search',
          path: '/search',
          onClick: () => {
            navigate("/search")
          }
        },
        {
          label: 'Buscas realizadas',
          key: 'orders',
          path: '/orders',
          icon: null,
          children: [
            {
              label: 'Buscas por telefone',
              key: 'orders-phone',
              icon: null,
              onClick: () => {
                navigate("/orders/phones")
              }
            },
            {
              label: 'Buscas por CPF/CNPJ',
              key: 'orders-targets',
              icon: null,
              onClick: () => {
                navigate("/orders/targets")
              }
            },
          ],
        },
        {
          label: 'Sair',
          icon: <LogoutOutlined />,
          key: 'login',
          path: '/login',
          onClick: () => {
            localStorage.removeItem("access_token");
            navigate("/login")
          }
        },
      ]

      if(!client.enabledSearch.includes("phone")) {
        list[1] = {
          label: 'Buscas realizadas',
          key: 'orders',
          path: '/orders/targets',
          icon: null,
          onClick: () => {
            navigate("/orders/targets")
          }
        }
      }
      
      if(localStorage.getItem("is_admin") === true || localStorage.getItem("is_admin") === 'true')
        list.splice(2, 0, {
            label: 'Cadastrar usuário',
            icon: null,
            key: 'register',
            path: '/register',
            onClick: () => {
              navigate("/register")
            }
          }
        )

      return list
    }

    useEffect(() => {
      buildMenuItems()?.forEach((item) => {
            if(window.location.href.includes(item.path)) {
                setCurrent(item.key)
            }
        })
    }, [location.pathname])

    
    return (

        <Header className="basic-header-logos">
            <Row>
                <Col span={4}>
                    <Link to="https://inquest.com.br/site/" target="__blank" className="img-logo">
                      <img src={logoInquest} alt="" />
                    </Link>
                </Col>
                <Col span={16}>
                {hasMenu && (
                    <Menu 
                      forceSubMenuRender={true}
                      style={{justifyContent: "center"}}
                      selectedKeys={[current]} 
                      mode="horizontal" 
                      items={buildMenuItems()} />
                )}
                </Col>
                <Col span={4}>
                  <Link to={client.externalLink} target="__blank"  className="img-logo">
                    <div className="bradesco-logo-wrapper">
                    <img src={client.logoImg} alt="" />
                    </div>
                  </Link>
                </Col>

                {showUser && (
                    <Col span={24} className='user-logged-info'>
                      <span>{localStorage.getItem("is_admin") == 'true' ? "Administrador" : "Usuário"}: </span>
                      <span>
                        <div> {localStorage.getItem("user_email")}</div>
                        <div> {localStorage.getItem("user_name")}</div>
                      </span>
                    </Col>  
                )}
            </Row>
        </Header>
    )
}

BasicHeader.defaultProps = {
  hasMenu: true,
  menuKey: null,
  showUser: false,
}

export default BasicHeader;