import React from 'react';



export default function AppProvider({ children }) {
  return (
    <>
        {children}
    </>
  );
}
