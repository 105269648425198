import React, { useState, useEffect } from "react";
import { Form, Input, Button, Layout, Row, Col } from "antd";
import { changePassword } from "../services/api";
import Swal from "sweetalert2";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BasicHeader from "../component/header";
import "../sass/pages.scss";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import BaseModal from "../component/modal";

const { Content } = Layout;

const InputPassword = (props) => {
  const [hidden, setHidden] = useState(true);

  return (
    <Input
      {...props}
      type={hidden ? "password" : "text"}
      suffix={
        hidden ? (
          <EyeOutlined onClick={() => setHidden(!hidden)} />
        ) : (
          <EyeInvisibleOutlined onClick={() => setHidden(!hidden)} />
        )
      }
    />
  );
};

export function ConfirmAccount() {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState({});
  const [success, setSuccess] = useState(false);
  const { token: changePasswordToken } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    console.log({ email });
    setEmail(email);
    form.setFieldsValue({ email })
  }, []);

  const handleSubmit = async (values) => {
    console.log({ values, changePasswordToken });

    try {
      const response = await changePassword(
        values.password,
        changePasswordToken
      );

      form.resetFields();
      form.setFieldsValue({ email })
      setSuccess(true)
      setShowModal({
        title: "Senha cadastrada",
        // subtitle: "",
        message:(<>
          Sua senha foi cadastrada com sucesso, clique abaixo para efetuar o 
          login com o usuário <b>{email}</b>.
        </>),
        okButtonText: "Ir para login",
        navigate: "/login",
      });
    } catch (error) {
      // Handle registration error here, e.g., display an error message
      if (error.response?.status === 401) {
        setShowModal({
          title: "Não foi possível alterar a senha",
          // subtitle: "",
          message:(<>
            A senha deste usuário já foi cadastrada anteriormente, solicite 
            novamente a alteração da senha ou clique abaixo para ir para página de login.
          </>),
          okButtonText: "Ir para login",
          navigate: "/login",
          cancelButtonText: "Solicitar alteração",
          // cancelNavigate: "/change-password",
        });
        return;
      } else {
        setShowModal({
          title: "Não foi possível alterar a senha",
          // subtitle: "",
          message:(<>
            Ocorreu um resultado inesperado ao cadastrar a senha, solicite novamente a alteração 
            de senha para prosseguir.
          </>),
          okButtonText: "Solicitar alteração",
          navigate: "/change-password",
        });
        return;
      }
    }
  };

  return (
    <>
      <BasicHeader menuKey={"unlogged"} />
      <div className="structure-confirm-accout">
        <Content className="register-form">
          <h3>Cadastrar senha</h3>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              initialValue={email}
              name="email"
              label="E-mail do usuário"
            >
              <Input readOnly={true} style={{border: "none"}} />
            </Form.Item>
            <Row justify={"space-between"} gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  name="password"
                  label="Senha"
                  rules={[
                    {
                      required: true,
                      message: <div>Campo obrigatório</div>,
                    },
                    {
                      min: 6,
                      message: (
                        <div>A senha deve ter pelo menos 6 caracteres</div>
                      ),
                    },
                    () => ({
                      validator(_, value) {
                        const pass = form.getFieldValue("confirm_password");
                        if (pass && pass.length > 0 && pass !== value) {
                          return Promise.reject(
                            new Error(
                              "A senha e confirma senha devem ser iguais"
                            )
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputPassword placeholder="" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="confirm_password"
                  label="Confirmar senha"
                  rules={[
                    {
                      required: true,
                      message: <div>Campo obrigatório</div>,
                    },
                    {
                      min: 6,
                      message: (
                        <div>A senha deve ter pelo menos 6 caracteres</div>
                      ),
                    },
                    () => ({
                      validator(_, value) {
                        if (form.getFieldValue("password") !== value) {
                          return Promise.reject(
                            new Error(
                              "A senha e confirma senha devem ser iguais"
                            )
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputPassword placeholder="" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            {success && (
              <div style={{color: "#1d945a"}}>
                Sua senha foi cadastrada com sucesso, prossiga para a página de login.
              </div>  
            )}
            </Row>
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Form>
        </Content>
      </div>
      <BaseModal
        isVisible={showModal && showModal?.title}
        title={showModal?.title ?? ""}
        subtitle={showModal?.subtitle ?? ""}
        onClose={() => setShowModal({})}
        okButtonText={showModal?.okButtonText}
        cancelButtonText={showModal?.cancelButtonText}
        onCancel={showModal?.cancelNavigate ?
          () => {
            if(showModal?.cancelNavigate) {
              navigate(showModal?.cancelNavigate)
            } else {
              setShowModal({})
            }
        } : null}
        onOk={() => {
          if(showModal?.navigate) {
            navigate(showModal?.navigate)
          } else {
            setShowModal({})
          }
        }}
      >
        {showModal?.message ?? ""}
      </BaseModal>
    </>
  );
}

export default ConfirmAccount;

