import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import BasicHeader from '../component/header';
import logoWhatsapp from "../assets/img/apple-touch-icon.png"
import { getPhoneSurvey } from '../services/api';
import { message, Spin } from 'antd';
import "../sass/pages.scss";

function validateBearerCode(bearerCode) {
    // Check if the bearer code is empty or invalid
    if (!bearerCode || bearerCode.length < 6) {
      return false;
    }
    // Additional validation logic if needed
  
    return true;
  }

export function Report() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [surveyData, setSurveyData] = useState({})
    const [isLoading, setLoading] = useState(true)

    const { phone, result } = surveyData
    
    useEffect(() => {
        // Check the bearer code when the component mounts
        const token = localStorage.getItem("access_token");
        const isValidBearerCode = validateBearerCode(token);
        if (!isValidBearerCode) {
            // Redirect to the login page
            navigate('/login?error=timeout');
        }
    }, [navigate]);


    useEffect(() => {
        fecthReportPhone(id)
    }, [id])


    const fecthReportPhone = async (id) => {
        try {
            setLoading(true)
            const surveyData = await getPhoneSurvey(id);
            setSurveyData(surveyData)
            setLoading(false)
        } catch (error) {
            message.error("Erro ao obter busca por telefone")
        }
    }

    const getSex = (sex) => {
        switch(sex) {
          case "M":
            return "Masculino"
          case "F":
            return "Feminino"
          case "I":
            return "Não cadastrado"
          default:
            return sex ?? "-"
        }
      }

    const isCellphone = (number) => {
        // Regular expression to match cellphone numbers
        const cellphonePattern = /^\(?(?:0?[1-9][1-9]|[1-9][0-9])\)?\s?9/;
        return cellphonePattern.test(number);
      };

    return (
        <>
      <BasicHeader />
      {isLoading ? (
        <div style={{width: "100%", textAlign: "center"}}>
          <Spin size="large" /> 
        </div>
      ) : (
      <Container className="report">
        <Row>
            <Col sm={12}>
                <div className='w-100 d-flex flex-column align-items-start justify-content-start'>
                    <h2 className='my-4'> {result?.name} </h2>
                    <h4 className='my-4'> Dados cadastrais </h4>
                </div>
            </Col>
            <Col sm={6}>
                <div className='w-100 d-flex flex-column align-items-start justify-content-start'>
                    <div className='mb-2'> 
                        <b>Proprietário: </b><span>{result?.name}</span>
                    </div>
                    {/* <div className='my-2'> 
                        <a href={data.dados_cadastrais.link_consulta_obito} target="_blank" rel="noreferrer">
                            Abrir consulta de óbito nacional
                        </a>
                    </div> */}
                    {/* <div className='my-2'>
                        <img src="./img/apple-touch-icon.png" className='whatsapp-icon' alt="WhatsApp Icon" /> 
                        <b> {data.dados_cadastrais.status} </b> {`(`} <a href={data.dados_cadastrais.status_url} > Ver histórico completo </a> {`)`}
                    </div> */}
                    {/* <div className='my-2'>
                        <b>Titulo de eleitor: </b> {data.dados_cadastrais.titulo_eleitor}
                    </div> */}
                </div>
            </Col>
            <Col sm={6}>
                    <div className='w-100 d-flex flex-column align-items-start justify-content-start'>
                        
                        <div className='mb-1'>
                            <b>Dt. Nascimento: </b>
                            <span>{result?.birthdate ?? "Não cadastrado"}</span>
                        </div>
                        {/* <div className='my-1'>
                            <b>Signo: </b>
                            <span>{birthdate}</span>
                        </div> */}
                        <div className='my-1'>
                            <b>Sexo: </b>
                            <span>{getSex(result?.sex)}</span>
                        </div>
                        <div className='my-1'>
                            <b>Nome da Mãe: </b>
                            <span>{result?.mother_name ?? "Não cadastrado"}</span>
                        </div>
                        {/* <div className='my-1'>
                            <b>Nome do Pai: </b>
                            <span>{birthdate}</span>
                        </div> */}
                        {/* <div className='my-1'>
                            <b>Nome do Cônjuge: </b>
                            <span>{birthdate}</span>
                        </div>
                        <div className='my-1'>
                            <b>CPF do Cônjuge: </b>
                            <span>{birthdate}</span>
                        </div> */}
                         <div className='my-1'>
                            <b>Phone: </b>
                            <span>{phone}</span>
                        </div>
                        <div className='my-1'>
                            <b>CPF/CNPJ: </b>
                            <span>{result?.cpf_cnpj ?? "Não cadastrado"}</span>
                        </div>
                    </div>
                </Col>
        </Row>
        <hr />
        <Row>
            <Col sm={12}>
                <h4 className="my-4">Telefones</h4>
                 {result?.phones?.map((phone, index) => (
                    <div key={index}>
                        <p>
                            <b>{'Contato '}{isCellphone(phone.number) ? '(Celular' : '(Telefone'}{phone.tipocobranca ? ` ${phone.tipocobranca}): ` : "): "} </b>

                            {typeof phone.number === 'number' && phone.number.toString().replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')}

                            {' '}
                            , Operadora: {phone.operating ?? " - "}
                            {phone.whatsapp && (
                                <img src={logoWhatsapp} className='whatsapp-icon' alt="WhatsApp Icon" />
                            )}
                        </p>
                    </div>
                ))}
                {result?.phones?.length === 0 && (
                    <span>Nenhum telefone encontrado</span>
                )}
            </Col>
        </Row>
        <hr />
        <Row>
            <Col sm={12}>
                <h4 className="my-4">Endereços</h4>
                {result?.address?.filter(x => !!x.complement)?.map((address, index) => (
                    <div key={index}>
                        <p><b> {`Endereço ${index + 1} : `}</b><span>
                            Rua: {address.complement} - CEP: {address.zipcode} - {address.city}
                        </span></p>
                    </div>
                ))}
                {result?.address?.filter(x => !!x.complement)?.length === 0 && (
                    <span>Nenhum endereço encontrado</span>
                )}
            </Col>
        </Row>
        <hr />
        <Row>
            <Col sm={12}>
                <h4 className="my-4">Emails</h4>
                {result?.emails.map((email, index) => (
                    <div key={index} className="mb-3">
                        <div>
                            <b> {`Email ${index + 1} : `}</b>
                            <span> {email.email} </span>
                        </div>
                    </div>
                ))}
                {result?.emails?.length === 0 && (
                    <span>Nenhum email encontrado</span>
                )}
            </Col>
        </Row>
        <hr />
      </Container>
      )}
    </>);
}