import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import AppProvider from './context/index';
import { Login } from "./pages/Login";
import { Report } from "./pages/Report";
import { Search } from "./pages/Search";
import { Order } from "./pages/Order";
import { Register } from "./pages/Register";
import "./sass/pages.scss";
import ConfirmAccount from "./pages/ConfirmAccount";
import { client } from "./clients";

const App = () => {

useEffect(() => {
    document.title = `${client.label} | Inquest`
}, [])

  return (
    <AppProvider>
        <Router>
            <Routes>
                <Route path="/" element={<Login/>} />
                <Route path="/login" element={<Login/>} />
                <Route path="/search" element={<Search/>} />
                <Route path="/report/:id" element={<Report/>} />
                <Route path="/orders/phones" element={<Order/>} />
                <Route path="/orders/targets" element={<Order/>} />
                <Route path="/register" element={<Register/>} />
                <Route path="/change-password/:token" element={<ConfirmAccount/>} />
            </Routes>
        </Router>
    </AppProvider>
    )
};

const root = document.getElementById("root");
ReactDOM.createRoot(root).render(<App />);
